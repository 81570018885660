import React, { useEffect, Fragment, useState } from "react"
import { Img } from "react-image"
import { scrollToElement } from "../../../utility/utils"
import AccountBoostForm from "../../../../assets/img/bank-account-form.svg"
import RepaymentLoanIllustration from "../../../../assets/img/kuda-available-loans.svg"
import LoanSummaryIllustration from "../../../../assets/img/kuda-loan-summary.svg"
import TotalDueIllustration from "../../../../assets/img/total-due.svg"
import LoanCalcIllustration from "../../../../assets/img/loan-calc.svg"
import EntryIllustration from "../../../../assets/img/loans-entry-illustration.inline.svg"
import CTA from "../general/cta"
import Card1 from "../../../../assets/img/blur/kuda-new-card-blur.inline.svg"
import BelowFoldCards from "../general/belowFoldCards"
import ImageTextLeft from "../general/imageTextLeft"
import ImageTextRight from "../general/imageTextRight"
import Person from "../../../../assets/img/userIcon.inline.svg"
import WithdrawIcon from "../../../../assets/img/withdrawIcon.inline.svg"
import Card from "../../../../assets/img/kuda-app-miniIcon.inline.svg"
import FAQList from "../general/faqsList"
import BadgesEntryFold from "../general/badgesEntryFold"
import { formatBalance } from "../../../../util/util"
import MoreForYouComponent from "../general/more-for-you"

import OverdraftIcon from '../../../../assets/img/more-for-you/kuda-overdrafts-icon.inline.svg'
import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaSalaryIcon from '../../../../assets//img/more-for-you/kuda-salary-icon.inline.svg'

const PersonalLoan = () => {
  const [loanLimit, setLoanLimt] = useState("150,000")
  const entryContent = {
    title: `Get a loan of up to ₦${loanLimit} on the Kuda app!`,
    subtitle: "Get a Kuda loan in minutes, repay in monthly installments.",
    illustration: <EntryIllustration />,
  }

  const kudaTopFeatures = [
    {
      icon: <WithdrawIcon />,
      text: `Get up to ₦${loanLimit} in minutes.`,
    },
    {
      icon: <Person />,
      text: "Boost loan offers with an account statement.",
    },
    {
      icon: <Card />,
      text: "Repay your Kuda loan in up to three months.",
    },
  ]

  const firstSection = {
    title: "Try out our loan calculator.",
    subtitle: "Set a loan amount and your preferred repayment time to see the estimated amount you’ll repay monthly if you take a Kuda Loan.",
    illustration: <Img alt="" src={LoanCalcIllustration} fallback={<Card1 className="blur isImage" />} className="pt-4 pt-lg-0" />,
    name: "Calculate Loan",
    url: "/en-ng/personal-loan/loan-calculator/"
  }

  const secondSection = {
    title: "Borrow without paperwork or stories.",
    subtitle: "Anyone can have an urgent money need. Get the amount you need quickly on the Kuda app.",
    illustration: <Img alt="" src={TotalDueIllustration} fallback={<Card1 className="blur isImage" />} className="pt-4 pt-lg-0" />,
  }
  const thirdSection = {
    title: `Get up to ₦${loanLimit} in your Kuda account in minutes.`,
    subtitle: "We’ll let you choose how much you’d like to borrow after our quick loan approval process.",
    illustration: <Img alt="" src={LoanSummaryIllustration} fallback={<Card1 className="blur isImage" />} className="pt-4 pt-lg-0" />,
    name: "Join Kuda",
    url: "/joinKuda/"
  }

  const forthSection = {
    title: "Repay your Kuda loan comfortably from your Kuda account.",
    subtitle: "Choose a monthly repayment option that’s convenient for you on the app.",
    illustration: <Img alt="" src={RepaymentLoanIllustration} fallback={<Card1 className="blur isImage" />} className="pt-4 pt-lg-0" />,
  }

  const fifthSection = {
    title: "Boost loan offers with an account statement.",
    subtitle: "Want a better loan offer? Add your account statement to increase your chances of getting the amount you want.",
    illustration: <Img alt="" src={AccountBoostForm} fallback={<Card1 className="blur isImage" />} />,
    name: "Join Kuda",
    url: "/joinKuda/"
  }
  const questions = [
    {
      heading: "What’s a Kuda loan?",
      list: (
        <span className="flex flex-column"><span>A Kuda loan is a one-off payment made into your Kuda account which you’ll repay, with interest,
          in fixed monthly payments you agree to when we approve your loan application. </span>
        </span>),
      index: 1
    },
    {
      heading: "Why should I take a loan from Kuda?",
      list: (
        <span className="flex flex-column"><span>Our loan application process is quick and easy, interest rates are competitive, and loan repayment is convenient.</span>
        </span>),
      index: 2
    },
    {
      heading: "Some loan apps only offer small amounts. How much can I borrow from Kuda?",
      list: (
        <span className="flex flex-column"><span>{`You can borrow up to ₦${loanLimit} from Kuda.`} </span>
        </span>),
      index: 3
    },
    {
      heading: "What are the requirements for getting a Kuda loan?",
      list: (
        <span className="flex flex-column"><span>You need to pass our loan eligibility checks to get a Kuda loan.</span>
        </span>),
      index: 4
    },
    {
      heading: "How do I apply for a loan?",
      list: (
        <span className="flex flex-column">
          <span>1. Download the Kuda app on your app store, then sign up.</span><br />
          <span>2. When you’re signed in to your Kuda app, tap <b>Borrow.</b></span><br />
          <span>3. Read the loan application guide and follow the steps.</span><br />
          <span>Note that if we approve your loan application, you’ll need to accept a loan offer letter which includes the terms and conditions of the loan.</span>
        </span>),
      index: 5
    },
    {
      heading: "Is there a guarantee that I’ll get a loan if I apply for one?",
      list: (
        <span className="flex flex-column"><span>We’ll offer you a loan if you pass our automated loan eligibility checks. The loan amount will depend on the results of those checks.</span>
        </span>),
      index: 6
    },
    {
      heading: "How long does it take to get a loan if my loan application is approved?",
      list: (
        <span className="flex flex-column"><span>We’ll pay the loan into your Kuda account in minutes. </span>
        </span>),
      index: 7
    },
    {
      heading: "Do I need a referee to get a loan?",
      list: (
        <span className="flex flex-column"><span>No, you don’t but you can add a referee to increase the chances of getting the amount you’d like to borrow. </span>
        </span>),
      index: 8
    },
    {
      heading: "Do I need collateral to get a loan?",
      list: (
        <span className="flex flex-column"><span>No, you don’t need collateral to get a loan from us. </span>
        </span>),
      index: 9
    },
    {
      heading: "How much time do I have to repay a loan?",
      list: (
        <span className="flex flex-column"><span>Depending on the repayment period stated in the offer letter, you can repay a loan between one
          to three months. </span>
        </span>),
      index: 10
    },
    {
      heading: "How do I repay my loan?",
      list: (
        <span className="flex flex-column"><span>When you accept our loan offer, we’ll ask you to add a debit card to your account and we’ll
          charge the card whenever your loan is due for repayment.</span>
        </span>),
      index: 11
    },
    {
      heading: "What’s the interest rate on a Kuda loan?",
      list: (
        <span className="flex flex-column"><span>Interest rates are variable, but the interest rate on your loan will be stated in the loan offer letter
          which you’ll have to accept to get the loan. </span>
        </span>),
      index: 12
    }
  ]

  const moreForYou = [

    {
      icon: <OverdraftIcon />,
      title: "Overdrafts",
      subText: "Spend above your account balance to meet your urgent needs and pay back at just 0.6% daily interest.",
      linkTo: `/en-ng/overdrafts/`
    },
    {
      icon: <KudaSalaryIcon />,
      title: "Business Loan",
      subText: `Get a collateral-free salary loan with a competitive interest rate to meet your urgent needs easily.`,
      linkTo: `/en-ng/business/loans/`
    },
    {
      icon: <TransferIcon />,
      title: "Transfer & Spend",
      subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
      linkTo: `/en-ng/spend/`
    },
  ]

  async function getFirebaseData(firebase) {
    const data = await firebase.FireBase()
    const firebaseTermLoans = firebase.getFirebaseTermLoans()
    if (firebaseTermLoans?.loanLimit) {
      const newValue = formatBalance(firebaseTermLoans?.loanLimit, 0)
      setLoanLimt(newValue)
    }

    return data
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
    import("../../../../firebase").then(firebase => {
      getFirebaseData(firebase)
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextLeft
        title={firstSection.title}
        subtitle={firstSection.subtitle}
        illustration={firstSection.illustration}
        name={firstSection.name}
        url={firstSection.url}
      />
      <ImageTextRight
        title={secondSection.title}
        subtitle={secondSection.subtitle}
        illustration={secondSection.illustration}
        name={secondSection.name}
        url={secondSection.url}
      />
      <ImageTextLeft
        title={thirdSection.title}
        subtitle={thirdSection.subtitle}
        illustration={thirdSection.illustration}
      />
      <ImageTextRight
        title={forthSection.title}
        subtitle={forthSection.subtitle}
        illustration={forthSection.illustration}
        name={forthSection.name}
        url={forthSection.url}
      />
      <ImageTextLeft
        title={fifthSection.title}
        subtitle={fifthSection.subtitle}
        illustration={fifthSection.illustration}
        name={fifthSection.name}
        url={fifthSection.url}
      />
      <FAQList title={"Kuda Loans FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTA />

    </Fragment>
  )
}

export default PersonalLoan
